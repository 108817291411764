.parentCard {
    border: white 2px solid;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.cardTitle {
    color: var(--primary);
}


.valueLabel {
    font-size: 1rem;
    color: #808080;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.valueData {
    font-size: 1.5rem !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}


.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}


.imageContainer {
    border-radius: 24px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.circleData {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 3px;
    font-size: small;
}

@keyframes blinker {

    0%,
    20%,
    100% {
        opacity: 1;
    }

    10%,
    30% {
        opacity: 0;
    }
}

@keyframes blinkerSlow {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.blink {
    animation: blinker 2s linear infinite;
}

.blinkSlow {
    animation: blinkerSlow 2s linear infinite;
}