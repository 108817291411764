.nav {
  height: 100%;
  width: 225px;
  /* Initially nav is hidden */
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffff;
  overflow-x: hidden;
  padding-top: 25px;
  transition: 0.5s;
  /* Smooth transition */

}


.navCloseBtn {
  position: relative;
  font-size: 20px;
  cursor: pointer;
  display: none;
  background-color: rgba(128, 128, 128, 0.2);
  border: none;
  color: #000000;
  border-radius: 8px;
  padding: 5px 15px;
}

.nav.open {
  width: 250px;
  /* Width of the sidebar */
}

.navHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* This will put space between the close button and the logo */
  padding: 0 25px;
  /* Adjust as needed */
}

.navLogo {
  /* Adjust the size as needed */
  height: 45px;
  width: auto;
}

/*naigation menus*/
.navLinks {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  /* Add padding as needed */
}

.navLink {
  display: inline-flex;
  align-items: center;
  color: #2b2b2b;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 10px;
  transition: color 0.3s, background-color 0.3s,
    /* Smooth transitions for color and background-color */
}

.navLink:hover {
  color: #000000;
  background-color: rgba(0, 0, 0, 0.1);
}


/* Styles that apply only for screens with max-width of 600px */
@media screen and (max-width: 700px) {
  .nav {
    width: 0;
    /* Initially nav is hidden on smaller screens */
  }

  .nav.open {
    width: 100%;
    /* Full width when opened */
  }

  .navLink {
    color: #020202;
  }

  .navCloseBtn {
    display: block;
  }

}