.toolBar{
  display: flex;
  align-items: center;
}

.navToggle {
  display: none;
  cursor: pointer;
  background-color: #f2f3f5;
  border-radius: 8px;
  border: none;
  font-size: 20px;
  color: #000000;
  padding: 5px 15px;
  margin-right: 15px;
}

@media screen and (max-width: 700px) {

    .navToggle {
        display: block; /* Show toggle and close button on smaller screens */
      }
  }
  

  