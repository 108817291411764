.App {
    background: linear-gradient(to right, rgb(243, 247, 250), rgb(244, 245, 247));
}


:root {
    --primary: rgb(60, 83, 157);
    --secondary: rgb(244, 245, 247);
}

/* Mouse */

.clickable {
    cursor: pointer;
  }

/* Appearance */
.circle{
    border-radius: 50%;
}

.rounded {
    border-radius: 8px;
}

.border {
    border: white solid 2px;
}

.border-primary{
    border: var(--primary);
}

.shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
}

.bg-white {
    background-color: white;
}

.bg-secondary{
    background-color: var(--secondary);
}

.bg-blur {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
}

.color-w {
    color: white;
}

.color-light{
    color: rgb(121, 121, 121);
}

/* Layouts */
.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.p-3 {
    padding: 10px;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

/*text */
.text-1{
    font-size: 0.75rem;
}

/* image */
.img {
    object-fit: cover;
    overflow: hidden;
}

.img-contain{
    object-fit: contain;
}

/* Display*/

@media (max-width: 700px) {
    .hide-on-small-screens {
      display: none;
    }
  }
  