.page{
  padding-bottom: 100px;
}

.cards {
  margin-right: -25px;
  margin-left: -25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
  margin-top: 25px;
  display: flex; /* Use flexbox instead of grid */
  overflow-x: auto; /* Allow horizontal scrolling */
  gap: 10px;
  /* Ensure items do not wrap */
  flex-wrap: nowrap;
  /* Optional: Align items in the center of the container */
  align-items: center;
}


.overviewParentDiv {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: space-between; /* Space out items when on the same line */
  align-items: center; /* Vertically align items when on the same line */
}

.overviewHeading {
  /* Ensure the heading doesn't grow or shrink */
  flex: 0 0 auto; 
  margin: 0; /* Adjust margin as needed */
}

.chart{
  width: 100%;
  height: 300px;
  margin-top: 50px;
}

.heading{
  margin-bottom: 25px;
}

